import React, {useEffect, useState} from 'react';
import EpisodeList from '../EpisodeList/EpisodeList';
import "./Home.scss"
import Nav from "../Nav/Nav";
import SearchBar from "../SearchBar/SearchBar";

const Home = ({baiaoEpisodes}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [shownEpisodes, setShownEpisodes] = useState([]);
  const [filter, setFilter] = useState("");
  const [clickSearch, setClickSearch] = useState(false);

  let tags = [];
  
  baiaoEpisodes && baiaoEpisodes.forEach(episode => {
    episode.Tags.forEach(tag => {
      if (tags.indexOf(tag) === -1) {
        tags.push(tag);
      }
    })
  });

  useEffect(() => {
    const tagsNumber = selectedTags.length;
    if (baiaoEpisodes && tagsNumber > 0 ) {
      let filteredList = [];
      baiaoEpisodes.forEach((episode) => {
        let count = 0;
        for (let tag of selectedTags) {
          if (episode.Tags.indexOf(tag) !== -1) {
            count++;
          }
        }
        if (count === tagsNumber) {
          filteredList.push(episode);
        }
      })
      setShownEpisodes(filteredList);
    } else {
      setShownEpisodes(baiaoEpisodes);
    }
  }, [selectedTags, baiaoEpisodes, setShownEpisodes]);

  useEffect(()=> {
    let filteredList = [];
    filteredList = baiaoEpisodes && baiaoEpisodes.filter((episode) => episode.News.toLowerCase().includes(filter));
    setShownEpisodes(filteredList);
  }, [filter, baiaoEpisodes])


  return (
    <section className="Home">
      <Nav clickSearch={clickSearch} setClickSearch={setClickSearch}/>
      <SearchBar tags={tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} filter={setFilter} clickSearch={clickSearch}/>
      <EpisodeList baiaoEpisodes={shownEpisodes}/>
    </section>
  );
}

export default Home;
