import React, { useState, useEffect, createRef } from "react";
import "./EpisodeDetails.scss";
import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg";
import { ReactComponent as Share } from "../../../assets/images/share.svg";
import { ReactComponent as Play } from "../../../assets/images/play.svg";
import { ReactComponent as NavigateBefore } from "../../../assets/images/navigate_before.svg";
import { ReactComponent as NavigateNext } from "../../../assets/images/navigate_next.svg";
import { Link, useParams } from 'react-router-dom';
import Tag from "../../home/Tag/Tag";
import baiaoEpisodes from "../../../data/baiao_binario.json";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from "react-share";

const EpisodeDetails = () => {
  const [shareButtonsVisible, setShareButtonsVisible] = useState(false);
  const { id } = useParams();
  const shareUrl = window.location.href;
  const shareTitle = "Veja este episódio de Baião Binário - um projeto mastertech";
  let episode;
  let ref = createRef(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  for (let baiaoEpisode of baiaoEpisodes) {
    if (Number(id) === baiaoEpisode.id) {
      episode = baiaoEpisode;
      break;
    }
  };

  const handleNotices = (direction) => {
    if (direction === 'left') {
      ref.current.scrollLeft -= 200;
    } else {
      ref.current.scrollLeft += 200;
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <main className="EpisodeDetails">
      <nav>
        <Link to="/">
          <button>
            <Arrow />
            voltar
          </button>
        </Link>
        <div className="share-buttons">
          <button onClick={() => setShareButtonsVisible(!shareButtonsVisible)}>
            <Share />
          </button>
          <div className={shareButtonsVisible ? "active" : ""}>
            <FacebookShareButton url={shareUrl} quote={shareTitle} round={true}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={shareTitle}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} title={shareTitle}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} separator=":: " title={shareTitle}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
          </div>
        </div>
      </nav>
      <section>
        <div>
          <iframe src={`https://youtube.com/embed/${episode && episode.YoutubeLink.slice(-12)}`} title="baião binário" frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </div>
        <article>
          <p>idioma da matéria</p>
          <p>{episode && episode.Language}</p>
          <h2>{episode && episode.News}</h2>
          {episode && episode.Paragraph && <p>{episode.Paragraph}</p>}
          <div>
            <a href={episode && episode.YoutubeComplete} rel="noopener noreferrer" target="_blank">
              <button>ver episódio completo</button>
            </a>
            <a href={episode && episode.SpotifyLink} rel="noopener noreferrer" target="_blank">
              <button>
                <p>ouvir versão em podcast</p>
                <span><Play /></span>
              </button>
            </a>
          </div>
        </article>
      </section>
      <section>
        <div>
          <h3>ingredientes dessa notícia (palavras-chave)</h3>
          <div>
            {episode && episode.Tags.map((tag, index) => <Tag name={tag} index={index} />)}
          </div>
          <h3>link de referência</h3>
          <a href={episode && episode.Link} rel="noopener noreferrer" target="_blank">{episode && episode.Link}</a>
        </div>
        <div className="related-news">
          <h3>outras notícias relacionadas ao seu filtro</h3>
          <div className="navigate">
            <button onClick={() => handleNotices('left')}><NavigateBefore /></button>
            <button onClick={() => handleNotices('right')}><NavigateNext /></button>
          </div>
          <div ref={ref}>
            {
              baiaoEpisodes.map((currentEpisode, index) => {
                return (currentEpisode.Tags.map((tag) => {
                  if (episode && episode.Tags.indexOf(tag) !== -1) {
                    return (
                      <Link to={`/episodio/${currentEpisode.id}`} onClick={scrollToTop}>
                        <img key={index} src={currentEpisode.Image} alt={currentEpisode.News} />
                      </Link>
                    )
                  }
                }))
              })
            }
          </div>
        </div>
      </section>
      <hr />
    </main>
  );
}

export default EpisodeDetails;
