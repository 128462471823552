import React, { useState } from "react";
import "./Nav.scss";
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/logo.png";
import youtube from "../../../assets/images/youtube.png";
import spotify from "../../../assets/images/spotify.png";
import {ReactComponent as SearchIcon} from "../../../assets/images/search.svg";

const Nav = ({clickSearch, setClickSearch}) => {

  const showSearchFieldMobile = () => setClickSearch(!clickSearch);

  return (
    <nav className="Nav">
      <div>
        <div className="logo">
          <img src={logo} alt="Baião Binário"/>
          <p>um projeto mastertech</p>
        </div>
        <SearchIcon onClick={showSearchFieldMobile}/>
      </div>
      <h2>Sua marmita de notícias</h2>
      <div>
        <p>ver episódios mais recentes</p>
        <div>
          <Link to={{pathname: "https://www.youtube.com/playlist?list=PLN9DsV8W0kVqsLXSCbLuOaQoMsxROmCe8"}} target="_blank">
            <img src={youtube} alt="logo do youtube"/>
          </Link>
          <Link to={{pathname:"https://open.spotify.com/show/1qW3tCUu0CiHsl3dzjNZkh?si=GHlzlhpXS4aMUbyS37aUhQ"}} target="_blank">
            <img src={spotify} alt="logo do spotify"/>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
