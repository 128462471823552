import React from 'react';
import './App.css';
import Home from "./home/Home/Home";
import baiaoEpisodes from "../data/baiao_binario.json";
import {BrowserRouter, Route} from "react-router-dom";
import Footer from "./global/Footer/Footer";
import EpisodeDetails from "./episode/EpisodeDetails/EpisodeDetails";

function App() {

  return (
    <>
      <BrowserRouter>
        <Route path="/" exact={true} render={(props) => <Home {...props} baiaoEpisodes={baiaoEpisodes}/>}/>
        <Route path="/episodio/:id" exact={false} render={(props) => <EpisodeDetails {...props}/>}/>
      </BrowserRouter>
      <Footer/>
    </>
  );
}

export default App;
