import React from 'react';
import './Footer.scss';
import {ReactComponent as FacebookIcon} from "../../../assets/images/facebook.svg";
import {ReactComponent as InstagramIcon} from "../../../assets/images/insta.svg";
import {ReactComponent as YoutubeIcon} from "../../../assets/images/youtube.svg";
import {ReactComponent as LinkedinIcon} from "../../../assets/images/linkedin.svg";
import {ReactComponent as MastertechLogo} from "../../../assets/images/logo-mastertech.svg";

const Footer = () => {
  return (
    <footer>
      <section>
        <div className="mt-video">
          <iframe title="Vídeo Mastertech" width="393" height="264" src="https://www.youtube.com/embed/ys8sgNDKE8s"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
        <div className="mt-links">
          <h3>Mastertech</h3>
          <a href="https://mastertech.com.br/" rel="noopener noreferrer" target="_blank">Sobre</a>
          <a href="https://corporativo.mastertech.com.br/" rel="noopener noreferrer" target="_blank">
            Corporativo
          </a>
          <a href="https://mastertech.com.br/politica-de-privacidade" rel="noopener noreferrer" target="_blank">
            Política de Privacidade
          </a>
          <a href="https://mastertech.com.br/politica-de-protecao-de-dados" rel="noopener noreferrer" target="_blank">
            Política de Proteção de Dados
          </a>
          <a href="https://mastertech.com.br/portaldotitular" rel="noopener noreferrer" target="_blank">
            Portal do Titular
          </a>
          <a href="https://mastertech.com.br/politica-de-privacidade" rel="noopener noreferrer" target="_blank">
            Trabalhe Conosco
          </a>
          <a href="https://blog.mastertech.com.br/" rel="noopener noreferrer" target="_blank">
            Blog
          </a>
        </div>
        <div className="mt-product">
          <h3>Produtos</h3>
          <div className="products">
            <div>
              <a href="https://mastertech.com.br/projeto/sprint" rel="noopener noreferrer" target="_blank">
                Sprint
              </a>
              <a href="https://mastertech.com.br/projeto/mashie" rel="noopener noreferrer" target="_blank">
                Mashie
              </a>
              <a href="https://e2w.mastertech.com.br/" rel="noopener noreferrer" target="_blank">
                E2W
              </a>
              <a href="https://mastertech.com.br/projeto/humanos-digitais" rel="noopener noreferrer" target="_blank">
                Humanos Digitais
              </a>
            </div>
            <div>
              <div>
                <a href="https://mastertech.com.br/projeto/trivium" rel="noopener noreferrer" target="_blank">
                  Trivium
                </a>
                <a href="https://jukebox.mastertech.com.br" rel="noopener noreferrer"
                   target="_blank">
                  Jukebox
                </a>
                <a href="https://nisia.mastertech.com.br/" rel="noopener noreferrer" target="_blank">
                  Nisia
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contact">
          <h3>Contato</h3>
          <a href="tel:+5511998011661">
            (11) 9 9801-1661
          </a>
          <a href="mailto:oi@mastertech.com.br">
            oi@mastertech.com.br
          </a>
          <p>Av. Paulista, 1374 - Bela Vista</p>
          <p>São Paulo/SP | WeWork Paulista</p>
        </div>
      </section>
      <section>
        <div>
          <MastertechLogo/>
        </div>
        <div>
          <p>&copy; Copyright Mastertech 2020</p>
        </div>
        <div>
          <a
            rel="noopener noreferrer"
            href="https://www.facebook.com/mastertech.tech/"
            target="_blank"
          >
            <FacebookIcon/>
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/mastertech.tech/"
            target="_blank"
          >
            <InstagramIcon/>
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.linkedin.com/school/mastertech.tech/"
            target="_blank"
          >
            <LinkedinIcon/>
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCVAwnfMBdeA7Zz2fQf8fo_w"
            target="_blank"
          >
            <YoutubeIcon/>
          </a>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
