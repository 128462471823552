import React from "react";
import "./Tag.scss";

const Tag = ({name, index, active, handleTagSelection}) => {

  return (
    <button className={`Tag ${active ? "active" : ""}`} key={index} onClick={()=>handleTagSelection(name)}>{name?.toLowerCase()}</button>
  );
}

export default Tag;
