import React, { useState, useEffect, useRef } from "react";
import "./EpisodeList.scss";
import Episode from "../Episode/Episode";

const EpisodeList = ({ baiaoEpisodes }) => {
  const [episodesToShow, setEpisodesToShow] = useState([]);
  const [count, setCount] = useState(1);
  const episodePerPage = 15;
  const scrollDiv = useRef();

  useEffect(() => {
    let arrayForHoldingEpisodes = [];
    if (baiaoEpisodes.length > 0) {
      for (let i = 0; i < episodePerPage * count; i++) {
        if (baiaoEpisodes && baiaoEpisodes[i] !== undefined) {
          arrayForHoldingEpisodes.push(baiaoEpisodes && baiaoEpisodes[i]);
        }
      }
      setEpisodesToShow(arrayForHoldingEpisodes);

    }
  }, [count, baiaoEpisodes, setEpisodesToShow]);

  const buttonBehavior = () => {
    setCount(count + 1);
    scrollDiv.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
  };

  return (
    <section ref={scrollDiv} className="EpisodeList">
      {episodesToShow.map((episode, index) => {
        return <Episode episode={episode} index={index} />;
      })}
      {baiaoEpisodes.length > episodePerPage &&
        episodesToShow.length !== baiaoEpisodes.length && (
          <button className="load-more" onClick={buttonBehavior}>
            Carregar mais...
          </button>
        )}
    </section>
  );
};
export default EpisodeList;
