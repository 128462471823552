import React from "react";
import "./Episode.scss";
import { ReactComponent as Plus } from "../../../assets/images/plus.svg";
import { Link } from "react-router-dom";

const Episode = ({ index, episode }) => {
  return (
    <div className={`Episode ${((index + 1) % 3 === 2) ? "up" : ""}`}>
      <Link to={`episodio/${episode.id}`}>
      <img key={index} src={episode && episode.Image} alt={episode && episode.News} />
      </Link>
      <div className="overlay">
        <Link to={`episodio/${episode.id}`}>
          <p>{episode && episode.News && episode.News.toUpperCase()}</p>
          <button><Plus /></button>
        </Link>
      </div>
    </div>
  );
}

export default Episode;
