import React, {useState} from "react";
import "./SearchBar.scss"
import {ReactComponent as Arrow} from "../../../assets/images/arrow.svg";
import Tag from "../Tag/Tag";

const SearchBar = ({tags, selectedTags, setSelectedTags, filter, clickSearch}) => {
  const [showTags, setShowTags] = useState(false);

  const handleTagSelection = (tag) => {
    const index = selectedTags.indexOf(tag);
    const tagsList = [...selectedTags];
    if (index !== -1) {
      tagsList.splice(index, 1);
    } else {
      tagsList.push(tag);
    }
    setSelectedTags(tagsList);
  }

  return (
    <section className="SearchBar">
      <input className={clickSearch ? "active": ""} type="text" placeholder="buscar por conteúdo ou palavra chave" onChange={e => filter(e.target.value.toLowerCase())}/>
      <div>
        <p onClick={() => setShowTags(!showTags)}>Filtre por ingredientes</p>
        <Arrow className={showTags ? "active" : ""} onClick={() => setShowTags(!showTags)}/>
        <div className={`tags ${showTags ? "active" : ""}`}>
          {tags.map((tag, index) => {
            const selected = selectedTags.indexOf(tag) !== -1;
            return (<Tag name={tag} index={index} active={selected} handleTagSelection={handleTagSelection}/>);
          })}
        </div>
      </div>
    </section>);
}

export default SearchBar;
